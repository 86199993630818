import React, { Component } from 'react'
import ComingSoon from '../components/ComingSoon'
import Layout from '../layouts/Layout'
import '../styles/scss/motion.scss'

export default class coming extends Component {
    render() {
        return (
            <Layout>
                <ComingSoon /> 
            </Layout>
        )
    }
}
