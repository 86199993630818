import React, { Component } from 'react'
import '../styles/scss/coming-soon.scss'

export default class ComingSoon extends Component {
  render() {
    return (
        <div className='coming-soon'>
            <div className='titles-coming-soon'>
                <p>Prepare yourself to be <b>immersed</b> in a</p>
                <p>world full of <b>breath-taking experiences.</b></p>
                <br />
                <p>it is the <b>Verse World</b> and it is coming soon </p>
            </div>
        </div>
    )
  }
}
