import React from 'react'
import Footer from '../components/Common/Footer'
import Header from '../components/Common/Header'
import SEO from '../components/Common/SEO'
import GoogleAnalytics from './GoogleAnalytics'

export default function Layout({ children }) {
    return (
        <>
            <div>
                <Header/>
                    {children}
                <Footer />
            </div>
        </>
    )
}